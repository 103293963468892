<template>
    <div>
        <div class="
            align-items-center
            border-grey
            qu-fc-color
            curser-pointer
        " v-b-modal.add-questions-modalv-b-modal.gpt-series-questions-modal>
            <span class="text-nowrap qu-margin-left-10">Import GPT Response</span>
        </div>
        <b-modal id="gpt-series-questions-modal" hide-footer size="lg" centered title="Add GPT Response" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
            error }}</b-alert>
                <a href="https://chatgpt.com/" target="_blank">Click to open Chat GPT</a>
                <br />
                Please add this line at the end of your GPT prompt:
                <br />
                <div v-html="formatQuestions()"></div>
                <feather-icon @click="copyOutputFormat" icon="CopyIcon" size="17" class="qu-icon-grey curser-pointer" />
                <textarea class="mt-1" style="width: 100%; height: 400px" v-model="questionText"></textarea>
                <div class="modal-footer">
                    <b-button :disabled="!(questionText.length > 0)" @click="$emit('createQuestionJson', questionText)"
                        variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Add
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormSelect,
    BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
        BFormSelect,
        BFormCheckbox,
    },
    data() {
        return {
            loading: false,
            errorMsg: "",
            questionText: "",
            alertMessageToast: ''
        };
    },
    computed: {
        hasError() {
            return this.error != "";
        },
    },
    mounted() {
        this.errorMsg = this.error;
    },
    methods: {
        formatQuestions() {
            let questionText = `Question: 1\n`;
            questionText += `title: What comes between April and June?\n`;
            questionText += `titleTts: What comes between April and June?\n`;
            questionText += `plugin: DragAndDrop\n`;
            questionText += `descriptions0title: \n`;
            questionText += `descriptions0tts: \n`;
            questionText += `descriptions0image: \n`;
            questionText += `patterns0expression: April,__,June\n`;
            questionText += `patterns0answer: April,May,June\n`;
            questionText += `patterns0image: \n`;
            questionText += `patterns0optionsGroups0title: March,May,August,July\n`;
            questionText += `patterns0optionsGroups0tts: March,May,August,July\n`;
            questionText += `patterns0optionsGroups0image: \n`;
            questionText += `hintText: \n`;
            questionText += `hintImage: \n`;

            return questionText;
        },
        copyOutputFormat() {
            navigator.clipboard.writeText(this.formatQuestions())
            this.alertMessageToast = 'Copied!';
            this.showToast("success");
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.$emit("resetGptModel")
        },
        resetModal() {
            this.$emit("resetGptModel")
            this.questionText = "";
        },
    },
    props: ["error"],
};
</script>
<style>
#add-questions-modal .card-body {
    padding: 0px;
}

.curser-pointer {
    cursor: pointer;
}
</style>
